window.applicationConfig = {
    clientID: "87d42a36-216c-4d8c-bfc7-ec2c4b86873a",
    authority: "https://login.microsoftonline.com/common",
    consentScopes: ["user.read"],
    graphEndpoint: "https://graph.microsoft.com/v1.0/me",
    unprotectedResources: ['directives/', 'images/'],
};


if (popupWindow) {

    if (devEnabled) console.log('MSAL popup window');

    angular.module(angularjsModuleName, ['ui.router', 'MsalAngular'])
        .config(['$httpProvider', 'msalAuthenticationServiceProvider', '$locationProvider', function ($httpProvider, msalProvider, $locationProvider) {
            if (devEnabled) console.log('MSAL popup config');

            msalProvider.init({
                clientID: applicationConfig.clientID,
                authority: applicationConfig.authority,
                tokenReceivedCallback: function (errorDesc, token) {
                    if (devEnabled) console.log("token received: in callback " + token);
                },
                optionalParams: {
                    cacheLocation: 'localStorage',
                    unprotectedResources: [],
                    storeAuthStateInCookie: false 
                },
                routeProtectionConfig: {           
                    popUp: false,
                    consentScopes: applicationConfig.consentScopes
                }
            }, $httpProvider);

            $locationProvider.html5Mode(false).hashPrefix('');
        }]);
}


if (app) app.config(['$httpProvider', 'msalAuthenticationServiceProvider',
    function ($httpProvider, msalProvider) {

        if (devEnabled) console.log('MSAL app config');

        msalProvider.init({
            clientID: applicationConfig.clientID,
            authority: applicationConfig.authority,
            optionalParams: {
                cacheLocation: 'localStorage',
                validateAuthority: true,
                navigateToLoginRequestUrl: true,     
                unprotectedResources: window.applicationConfig.unprotectedResources,
                storeAuthStateInCookie: false 
            },
            routeProtectionConfig: {
                popUp: false,
                consentScopes: applicationConfig.consentScopes
            }
        }, $httpProvider,
            devEnabled
        );
    }]
);


if (app) app.controller('authCtrl', ['$scope', 'msalAuthenticationService', '$rootScope', '$log', 'infoLib',
    function ($scope, msalService, $rootScope, $log, infoLib) {

        $scope.loaded = true;

        $scope.login = function () {
            $log.debug("auth: login");
            msalService.loginRedirect();
        };

        $scope.logout = function () {
            $log.debug("auth: logout");
            msalService.logout();
        };

        $scope.$on("msal:acquireTokenSuccess", function () {
            $log.debug("auth: token success");
            infoLib.refreshLibrary();
        });

        $scope.$on("msal:acquireTokenFailure", function (event, errorDesc, error) {

            if (error.indexOf("consent_required") !== -1 || error.indexOf("interaction_required") != -1) {
                msalService.loginRedirect().then(function () {
                    $log.error("auth: token ok");
                }, function () {
                    $log.error("auth: token failure");
                });
            }
        });

        $scope.$on("msal:loginSuccess", function () {
            $log.debug("auth: login success user - %s  Auth Ok: %s", $rootScope.userInfo.userName, $rootScope.userInfo.isAuthenticated);
            infoLib.refreshLibrary();      
        });

        $scope.$on("msal:loginFailure", function (event, error) {
            $log.error("auth: login failure  Event: %s  Error: %s", event, error);
        });


        $scope.$on("msal:notAuthorized", function () {
            $log.error("auth: not authorised");
        });

    }]
)
