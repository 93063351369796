if (app) app.controller('MachinesController', ['$scope', '$location', '$stateParams', '$log',  'editMode',
    function ($scope, $location, $stateParams, $log, editMode) {

        $log.debug('MachinesController init');

        editMode.refresh();
        $scope.brand = $stateParams.brand;
        $scope.familyName = $stateParams.family;


              $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };
    }]
)
