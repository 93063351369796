var info ={
  "languages": [{
    "id": "EN",
    "name": "English"
  }, {
    "id": "DA",
    "name": "Danish"
  }, {
    "id": "FR",
    "name": "French"
  }, {
    "id": "DE",
    "name": "German"
  }, {
    "id": "JA",
    "name": "Japanese"
  }, {
    "id": "ES",
    "name": "Spanish"
  }, {
    "id": "SV",
    "name": "Swedish"
  }, {
    "id": "FI",
    "name": "Finnish"
  }, {
    "id": "IT",
    "name": "Italian"
  }, {
    "id": "PL",
    "name": "Polish"
  }, {
    "id": "RU",
    "name": "Russian"
  }, {
    "id": "PT",
    "name": "Portuguese"
  }, {
    "id": "NO",
    "name": "Norwegian"
  }, {
    "id": "LT",
    "name": "Lithuanian"
  }, {
    "id": "LV",
    "name": "Latvian"
  }, {
    "id": "TR",
    "name": "Turkish"
  }, {
    "id": "ZH",
    "name": "Chinese"
  }, {
    "id": "NL",
    "name": "Dutch"
  }, {
    "id": "TH",
    "name": "Thai"
  }, {
    "id": "SL",
    "name": "Slovenian"
  }],
  "brands": [{
    "name": "STERIS",
    "scopes": ["read:brandSteris"]
  }, {
    "name": "Medisafe",
    "scopes": ["read:brandMedisafe"]
  }],
  "docTypes": [{
    "name": "Operator Manual",
    "id": "userManual",
    "icon": "far fa-user",
    "hasLanguages": true,
    "hasIssues": true,
    "scopes": ["read:userManual"]
  }, {
    "name": "Service",
    "id": "service",
    "icon": "fas fa-tools",
    "hasIssues": true,
    "scopes": ["read:serviceManual"],
    "subTypes": [{
      "name": "Service Manual",
      "id": "serviceManual"
    }, {
      "name": "Install Manual",
      "id": "install"
    }, {
      "name": "Maintenance Manual",
      "id": "maintenance"
    }, {
      "name": "Illustrated Parts Breakdown",
      "id": "ipb"
    }]
  }, {
    "name": "Engineering Drawings",
    "id": "drawings",
    "icon": "fas fa-drafting-compass",
    "hasIssues": true,
    "scopes": ["read:drawings"]
  }, {
    "name": "Wiring Schematic",
    "id": "wiringSchematic",
    "icon": "fas fa-bolt",
    "hasIssues": true,
    "scopes": ["read:wiring"],
    "subTypes": [{
      "name": "Service",
      "id": "wiringService"
    }, {
      "name": "Production",
      "id": "wiringProduction",
      "scopes": ["read:wiringProduction"]
    }]
  }, {
    "name": "Tech Bulletin",
    "id": "bulletins",
    "icon": "fas fa-file-alt",
    "hasDescription": true,
    "scopes": ["read:techBulletin"]
  }, {
    "name": "Other",
    "id": "other",
    "icon": "fas fa-file",
    "hasDescription": true,
    "scopes": ["read:other"]
  }]
};