if (app) app.controller('DocsController', ['$scope', '$rootScope', '$location', '$stateParams', '$log', 'infoLib', 'editMode',
    function ($scope, $rootScope, $location, $stateParams, $log, infoLib, editMode) {

        $log.debug('DocsController init');

        infoLib.status.viewPdf = null;
        editMode.refresh();
        $scope.brand = $stateParams.brand;
        $scope.familyName = $stateParams.family;
        $scope.machineName = $stateParams.machine;
        $scope.selectedCategory = {
            type: null,
            docs: [],
            filteredDocs: [],
            issue: '',
            language: info.languages[0],
            subType: '',
            drawingNumber: null,
            description: null
        };

        $scope.categories = [];
        $scope.hasDocuments = false;

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };

        $scope.$watch(
            function () { return infoLib.data },

            function (newInfoLibrary) {

                $log.debug('info library refresh - start:', newInfoLibrary);

                var hasDocuments = false;

                $scope.categories = [];

                $rootScope.info.docTypes.filter(type => newInfoLibrary.allowedCategories.some(cat => cat === type.id)).forEach(type => {

                    var docs = newInfoLibrary.docs.filter(o =>
                        (o.docTypeId == type.id) &&
                        (o.machine == $scope.machineName) &&
                        (o.family == $scope.familyName));

                    var typeCategory = { type: type, enabled: docs.length > 0 };

                    if (typeCategory.enabled) {
                        hasDocuments = true;
                    }

                    $scope.categories.push(typeCategory);

                    if ((typeof (type.subTypes) != 'undefined')) {
                        var subTypes = type.subTypes.filter(o => docs.find(d => d.subTypeId === o.id));

                        if (subTypes.length > 1) {

                            typeCategory.hasSubTypes = true;

                            subTypes.forEach(t => $scope.categories.push(
                                {
                                    enabled: true,
                                    type:
                                    {
                                        id: typeCategory.type.id,
                                        name: t.name,
                                        scopes: typeCategory.type.scopes,
                                        subType: t,
                                        hasIssues: typeCategory.type.hasIssues
                                    }
                                }
                            ));
                        }
                    }
                });


                if (($scope.categories != null) && (typeof ($scope.categories) != 'undefined')) {
                    if ($scope.categories.length === 1) {
                        $scope.selectedCategory.type = $scope.categories[0].type;
                    }
                }


                if ($scope.selectedCategory) {
                    if ($scope.selectedCategory.type) {
                        $scope.selectDocType($scope.selectedCategory.type, true);
                    }
                }

                $scope.hasDocuments = hasDocuments;
                $log.debug('info library refresh - categories:', $scope.categories);
            },

            true
        )

        $scope.selectDoc = function (doc) {
            $scope.selectedCategory.doc = doc;
            infoLib.viewPdf($scope.selectedCategory.doc.cdnLink);
        }

        $scope.clearSelection = function () {
            $scope.selectedCategory.doc = null;
            $scope.selectedCategory.docs = null;
            $scope.selectedCategory.type = null;
            infoLib.status.viewPdf.cdnLink = null;
        }

        $scope.selectDocType = function (docType, forceRefresh) {

            $log.debug('selectDocType:', docType);

            if ($scope.selectedCategory && !forceRefresh) {
                if ($scope.selectedCategory.type == docType) {
                    return;
                }
            }

            $scope.selectedCategory.type = docType;
            $scope.selectedCategory.subType = docType.subType;
            $scope.refreshDocs();

            $log.debug('selected document:', $scope.selectedCategory.doc);
        }

        $scope.deleteSelectedDoc = function () {
            infoLib.deleteDoc($scope.selectedCategory.doc);
            $scope.clearSelection();
        }

        $scope.refreshDocs = function () {

            if (typeof ($scope.selectedCategory.type) == 'undefined')
                return;

            var docs = infoLib.data.docs.filter(o =>
                (o.docTypeId === $scope.selectedCategory.type.id) &&
                (o.machine === $scope.machineName) &&
                (o.family === $scope.familyName)).sort((a, b) => {
                    var aCat = a.issue?.padStart(5, '0') + '|' + a.docNumber;
                    var bCat = b.issue?.padStart(5, '0') + '|' + b.docNumber;

                    return (aCat < bCat ? 1 : aCat > bCat ? -1 : 0);
                });

            if (typeof ($scope.selectedCategory.subType) != 'undefined') {
                docs = docs.filter(o => o.subTypeId === $scope.selectedCategory.subType.id);
            }

            if (docs.length == 0) {
                $scope.selectedCategory.docs = null;
                return;
            }

            $scope.selectedCategory.docs = [...docs];


            if ($scope.selectedCategory.type.hasLanguages) {
                if (!docs.some(o => o.language == $scope.selectedCategory.language.id)) {
                    $scope.selectedCategory.language = info.languages.find(o => o.id == docs[0].language);
                }

                docs = docs.filter(o => o.language === $scope.selectedCategory.language.id);
            }


            if ($scope.selectedCategory.type.id == 'drawings') {
                if (!$scope.selectedCategory.drawingNumber) {
                    $scope.selectedCategory.drawingNumber = docs[0].docNumber;
                }
                docs = docs.filter(o => o.docNumber === $scope.selectedCategory.drawingNumber);
            }


            $scope.selectedCategory.filteredDocs = docs;

            if (($scope.selectedCategory.doc == null) || (typeof ($scope.selectedCategory.doc) == 'undefined')) {
                $scope.selectedCategory.doc = $scope.selectedCategory.filteredDocs[0];
            }
            else {
                $scope.selectedCategory.doc = $scope.selectedCategory.filteredDocs.find(o => o._id === $scope.selectedCategory.doc._id);

                if (($scope.selectedCategory.doc == null) || (typeof ($scope.selectedCategory.doc) == 'undefined')) {
                    $scope.selectedCategory.doc = $scope.selectedCategory.filteredDocs[0];
                }
            }

            $scope.selectedCategory.issue = Math.max.apply(Math, $scope.selectedCategory.filteredDocs.map(o => { return o.issue }));
            $scope.selectedDocChanged();
            $log.debug('selected category:', $scope.selectedCategory);
        }

        $scope.selectedDocChanged = function () {
            if ($scope.selectedCategory.type.id == 'drawings') {
                infoLib.viewPdf($scope.selectedCategory.doc.drawingPack.pdfList[0].cdnLink, $scope.selectedCategory.doc.drawingPack);
            }
            else if (typeof ($scope.selectedCategory.doc.cdnLink) != 'undefined') {
                infoLib.viewPdf($scope.selectedCategory.doc.cdnLink);
            }
        }

        $scope.getDocDescription = function (doc) {
            var description = doc.docNumber + ' ' + doc.description;
            return description;
        }

        $scope.getDrawingDescription = function (drawing) { 
            var description = drawing.drawingPack.description.split('-').pop().trim();
            description = drawing.docNumber + ' ' + description
            return description;
        }

        $scope.filter = {
            languageExists: function (language) {

                if ($scope.selectedCategory.type == null)
                    return false;

                var docs = infoLib.data.docs.filter(o =>
                    (o.docTypeId == $scope.selectedCategory.type.id) &&
                    (o.machine == $scope.machineName) &&
                    (o.family == $scope.familyName));

                return docs.some(o => o.language == language.id);
            },

            subTypeExists: function (subType) {

                if ($scope.selectedCategory.type == null)
                    return false;

                var docs = infoLib.data.docs.filter(o =>
                    (o.docTypeId == $scope.selectedCategory.type.id) &&
                    (o.machine == $scope.machineName) &&
                    (o.family == $scope.familyName));

                return docs.some(o => o.subTypeId == subType.id);
            }
        }

    }]
)
