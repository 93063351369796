if (app) app.factory('infoLib', ['$rootScope', '$http', '$q', '$timeout', '$log', 'NgTableParams', 'preloader',
    function ($rootScope, $http, $q, $timeout, $log, NgTableParams, preloader) {

        var data = {
            docs: [],
            families: [],
            brands: [],
            machines: [],
            images: [],
            docsTable: null,
            techBulletinsTable: null,
            isEditor: false,
            allowedCategories: [],
            status: 'init'
        };

        var edit = {
            machine: null,
            doc: null,
        };

        var status = {
            saving: 'idle',
            saveError: '',
            addType: '',
            isAddGlobal: false,

            image: {
                saving: 'idle',
                saveError: ''
            },

            viewPdf: null,

            savingAction: function () {
                return this.saving.split(':').pop();
            }
        };

        var newDoc = {
            docTypeId: null,
            subTypeId: '',
            docNumber: null,
            brand: '',
            family: '',
            machine: '',
            description: '',
            cdnLink: '',
            drawingPack: null,
            issue: null,
            language: 'English',
            timestamp: new Date(),
            user: ''
        };

        const fileSources = ['File', 'Link'];
        var newDocSource = fileSources[0];

        function addDocClear() {
            newDoc.docTypeId = null;
            newDoc.subTypeId = '';
            newDoc.docNumber = null;
            newDoc.issue = null;
            newDoc.description = null;
            newDoc.language = 'EN';
            newDoc.user = null;
            newDoc.timestamp = new Date();
            newDoc.cdnLink = '';
            newDoc.drawingPack = null;
            status.saving = 'idle'
            status.addType = 'add';
        }


        function refreshLibrary() {

            $log.debug('refreshing info library...');

            $http.get('infoLibraryWeb').then(function (response) {
                data.docs = response.data.docs;
                data.images = response.data.images;
                data.brands = data.docs.unique('brand').map(o => o.brand);
                data.families = data.docs.unique('family').map(o => { return { name: o.family, brand: o.brand } });
                data.machines = data.docs.unique('machine').map(o => o.machine);
                data.isEditor = response.data.isEditor;
                data.allowedCategories = response.data.allowedCategories;

                data.lastUpdatedDoc = data.docs.sort(function (a, b) {
                    a = new Date(a.updatedAt);
                    b = new Date(b.updatedAt);
                    return a > b ? -1 : a < b ? 1 : 0;
                })[0];

                $log.debug('info library recieved:');
                $log.debug(data);

                const imagesToPreload = data.images.map(o => o.cdnLink);
                preloader.preloadImages(imagesToPreload).then(()=> {
                    $log.debug('images preload complete', imagesToPreload);
                },
                function() {
                    $log.debug('images preload failed', imagesToPreload);
                });


                var tableDataset = JSON.parse(JSON.stringify(data.docs));
                tableDataset.forEach(doc => {

                    var docType = $rootScope.info.docTypes.find(o => o.id === doc.docTypeId);

                    if (doc.subTypeId) {
                        doc.docTypeName = docType.name + " - " + docType.subTypes.find(o => o.id == doc.subTypeId).name;
                    }
                    else {
                        doc.docTypeName = docType.name;
                    }

                    doc.fullDescription = doc.docTypeName;

                    if (doc.description != null && typeof (doc.description) != 'undefined') {
                        doc.fullDescription += ' - ' + doc.description;
                    }

                    doc.languageName = $rootScope.info.languages.find(o => o.id == doc.language).name;
                });

                data.docsTable = new NgTableParams(
                    {
                        count: 20,
                        sorting: { timestamp: "desc" }
                    },
                    {
                        counts: [20, 50, 100, 200, 500, 1000],
                        paginationMaxBlocks: 5,
                        paginationMinBlocks: 2,
                        filterDelay: 0,
                        dataset: tableDataset
                    }
                );


                var techBulletinsDataset = JSON.parse(JSON.stringify(data.docs)).filter(o => o.docTypeId == 'bulletins').unique('docNumber');

                techBulletinsDataset.forEach(doc => {
                    delete doc.brand;
                    delete doc.family;
                    delete doc.issue;
                    delete doc.user;
                    delete doc.language;
                    delete doc.machine;
                    var relatedLinks = data.docs.filter(o => (o.docTypeId == 'bulletins') && (o.docNumber == doc.docNumber));
                    doc.machines = relatedLinks.map(o => o.machine).sort().join(', ');
                });

                $log.debug('techninal bulletins:');
                $log.debug(techBulletinsDataset);

                data.techBulletinsTable = new NgTableParams(
                    {
                        sorting: { timestamp: "desc" }
                    },
                    {
                        counts: [10, 50, 100, 200],
                        filterDelay: 0,
                        dataset: techBulletinsDataset
                    }
                );

                data.status = 'ok';

            }).catch(function (error) {
                if ((error.status === 401) || (error.status === 403)) {
                    data.status = 'unauthorised';
                    $log.debug("unauthorised user");
                } else {
                    data.status = 'error';
                    $log.error("Unable to load info library manifest");
                }

                $log.debug(error);
                $log.debug(data);
            });
        }


        function uploadData(type, url, data) {
            status.saving = type + ':uploading';
            status.saveError = '';

            $http({
                url: url,
                method: 'POST',
                data: angular.toJson(data)
            }).then(
                (response) => uploadSucess(response, data),
                (err) => uploadFailed(err)
            );
        }

        function uploadSucess(response, data) {
            $timeout(function () {
                status.saving = status.saving.split(':')[0] + ':success';
                $log.debug("upload success data:", status.saving, data);
                refreshLibrary();
                $timeout(function () { addDocClear(); }, 2000);  
            }, 500);
        }

        function uploadFailed(err) {
            status.saving = status.saving.split(':')[0] + 'error';
            status.saveError = err;
            $log.error("Error doc not saved:", err);
            $timeout(function () { addDocClear(); }, 10000);  
        }

        function addDocLinkToBlobStorage(doc, hasFile) {

            doc.user = $rootScope.userInfo.userName;
            status.saving = status.addType + ':uploading';
            status.saveError = '';

            $http({
                url: hasFile ? '/addDocLink' : '/addDocWithoutFile',
                method: 'POST',
                data: angular.toJson(doc)
            }).then(
                (response) => uploadSucess(response, data),
                (err) => uploadFailed(err)
            );
        }


        return {

            status: status,
            data: data,
            edit: edit,
            newDoc: newDoc,
            fileSources: fileSources,
            newDocSource: newDocSource,
            refreshLibrary: refreshLibrary,
            addDocClear: addDocClear,

            canViewCategory: function (categoryID) {
                return data.allowedCategories.some(o => o === categoryID);
            },

            isUploading: function () {
                return status.saving.includes(':uploading') ||
                    status.saving.includes(':success');
            },

            addDocInit: function (brand, familyName, machineName, basedOnDoc) {
                if ((machineName == null) || (typeof (machineName) == 'undefined')) {
                    status.isAddGlobal = true;
                    newDoc.brand = brand;
                    newDoc.family = '';
                    newDoc.machine = '';
                }
                else {
                    newDoc.brand = brand;
                    newDoc.family = familyName;
                    newDoc.machine = machineName;
                    status.isAddGlobal = false;
                }

                addDocClear();

                if ((basedOnDoc != null) && (typeof (basedOnDoc) != 'undefined')) {
                    status.addType = 'newIssue';
                    newDoc.docTypeId = basedOnDoc.docTypeId;
                    newDoc.subTypeId = basedOnDoc.subTypeId;
                    newDoc.docNumber = basedOnDoc.docNumber;
                    newDoc.description = basedOnDoc.description;
                    newDoc.language = basedOnDoc.language;
                }
            },

            addDoc: function (files) {
                var file = files[0];
                var formData = new FormData();
                newDoc.user = $rootScope.userInfo.userName;
                status.saving = status.addType + ':uploading';
                status.saveError = '';
                formData.append('file', file, angular.toJson(newDoc));

                $http({
                    url: '/addDoc',
                    method: 'POST',
                    headers: { 'Content-Type': undefined },
                    data: formData
                }).then(
                    (response) => uploadSucess(response, newDoc),
                    (err) => uploadFailed(err)
                );
            },

            addDocLink: function () {
                addDocLinkToBlobStorage(newDoc, true);
            },

            addDocLinkFrom: function (sourceDoc, destDocGroup) {
                var doc = JSON.parse(JSON.stringify(sourceDoc));
                delete doc._id;
                doc.brand = destDocGroup.brand;
                doc.family = destDocGroup.family;
                doc.machine = destDocGroup.machine;
                status.addType = 'copy';
                addDocLinkToBlobStorage(doc, true);
            },

            addLanguageChanged: function () {
                if (newDoc.docTypeId != 'userManual') {
                    newDoc.language = 'EN';
                }
            },


            deleteDoc: function (doc) {  

                if (!doc) {
                    $log.debug("no doc data to delete");
                    return;
                }

                $http({
                    url: '/deleteDoc',
                    method: 'POST',
                    data: angular.toJson(doc)
                }).then(
                    () => {
                        $log.info("doc deleted");
                        status.viewPdf = null;
                        refreshLibrary();
                    },
                    (response) => {
                        $log.error('doc delete error: %s', response.data);
                    }
                );
            },


            editDocInit: function (doc) {
                $log.debug('editDoc Init:', doc);
                edit.doc = JSON.parse(JSON.stringify(doc));
                edit.doc.timestamp = new Date(edit.doc.timestamp);
            },

            saveEditDoc: function () {
                edit.doc.user = $rootScope.userInfo.userName;
                uploadData('editDoc', '/updateDoc', edit.doc);
            },

            replaceDoc: function (files) {
                var file = files[0];
                var formData = new FormData();
                edit.doc.user = $rootScope.userInfo.userName;
                status.saving = 'editDoc:uploading';
                status.saveError = '';
                formData.append('file', file, angular.toJson(edit.doc));

                $http({
                    url: '/replaceDoc',
                    method: 'POST',
                    headers: { 'Content-Type': undefined },
                    data: formData
                }).then(
                    (response) => uploadSucess(response, edit.doc),
                    (err) => uploadFailed(err)
                );
            },


            editMachineInit: function (brand, familyName, machineName) {
                edit.machine = {
                    brand: brand,
                    family: familyName,
                    machine: machineName,
                    original: {
                        brand: brand,
                        family: familyName,
                        machine: machineName
                    }
                };
            },

            saveEditMachine: function () {
                edit.machine.user = $rootScope.userInfo.userName;
                uploadData('editMachine', '/updateMachineInfo', edit.machine);
            },


            addImage: function (files, brand, machine) {
                var file = files[0];
                var formData = new FormData();
                var newImage = {
                    user: $rootScope.userInfo.userName,
                    brand: brand,
                    machine: machine,
                    timestamp: Date.now()
                };

                status.saving = 'image:uploading';
                status.saveError = '';
                formData.append('file', file, angular.toJson(newImage));

                $http({
                    url: '/addImage',
                    method: 'POST',
                    headers: { 'Content-Type': undefined },
                    data: formData
                }).then(
                    (response) => uploadSucess(response, newImage),
                    (err) => uploadFailed(err)
                );
            },

            machineImage: function (brand, machine) {
                if ((typeof (brand) == 'undefined') || (typeof (machine) == 'undefined')) {
                    return '';
                }
                else if ((brand == null) || (machine == null)) {
                    return '';
                }
                else {
                    var image = data.images.find(o => (o.brand === brand) && (o.machine === machine));
                    return image ? image.cdnLink : '';
                }
            },


            addDrawingPack: function (files) {

                if (!files)
                    return;

                var drawingsInfoFile = null;
                for (var i = 0; i < files.length; i++) {
                    if (files[i].name == 'drawings.json') {
                        drawingsInfoFile = files[i];
                        break;
                    }
                }

                if (!drawingsInfoFile)
                    return uploadFailed('drawings.info not found');

                status.saving = status.addType + ':uploading';
                status.saveError = '';


                var reader = new FileReader();
                reader.onload = function (event) {

                    var drawingInfo = null;
                    var flatBom = [];
                    var pdfList = [];

                    function getDrawingInfo(assemblyDrawing) {

                        var drawingInfo = {
                            partNo: assemblyDrawing.number.toUpperCase().trim(),
                            description: assemblyDrawing.description,
                            drawings: []
                        };


                        pdfList.push({ number: drawingInfo.partNo });


                        var partNos = assemblyDrawing.bomColumnData["PART NUMBER"];
                        var descriptions = assemblyDrawing.bomColumnData["DESCRIPTION"];
                        var serviceSpares = assemblyDrawing.bomColumnData["isServiceSpare"];

                        for (var i = 0; i < partNos.length; i++) {

                            var partNo = partNos[i].toUpperCase().trim();
                            var bomItem = flatBom.find(o => o.partNo === partNo);

                            if (bomItem) {
                                bomItem.usedInDrawings.push(drawingInfo.partNo);
                                bomItem.usedInDrawings = bomItem.usedInDrawings.sort((a, b) => a > b ? -1 : 1);
                            }
                            else {
                                bomItem = {
                                    partNo: partNos[i].toUpperCase().trim(),
                                    description: descriptions[i].toLowerCase().trim(),
                                    serviceSpare: serviceSpares[i].toLowerCase().trim(),
                                    usedInDrawings: [drawingInfo.partNo]
                                };

                                flatBom.push(bomItem);
                            }
                        }


                        assemblyDrawing.drawings.forEach(subDrawing => {
                            if (!subDrawing.isFabrication) {
                                drawingInfo.drawings.push(getDrawingInfo(subDrawing, flatBom));
                            }
                        });

                        return drawingInfo;
                    }


                    try {
                        drawingInfo = getDrawingInfo(JSON.parse(event.target.result)[0]);
                        drawingInfo.flatBom = flatBom;
                        drawingInfo.pdfList = pdfList;
                    }
                    catch (err) {
                        return uploadFailed('no valid data found in drawings.info - ' + err.message);
                    }


                    var uploadForms = [];
                    var missingDrawings = [];

                    if (!drawingInfo.pdfList.every(pdf => {
                        for (var i = 0; i < files.length; i++) {
                            var file = files[i];

                            if (file.name.toUpperCase().trim() === pdf.number + '.PDF') {
                                var formData = new FormData();
                                formData.append('file', file, 'drawings/' + drawingInfo.partNo + '/' + 'issue ' + newDoc.issue + '/' + file.name);
                                uploadForms.push(formData);
                                return true;
                            }
                        }

                        missingDrawings.push(pdf.number + '.PDF');
                        return false;

                    })) {
                        return uploadFailed('missing drawings ' + missingDrawings.join());
                    }

                    var fileUploads = [];
                    uploadForms.forEach(formData => {
                        fileUploads.push($http({
                            url: '/addFile',
                            method: 'POST',
                            headers: { 'Content-Type': undefined },
                            data: formData
                        }));
                    });

                    $q.all(fileUploads).then(function (responces) {

                        if (!drawingInfo.pdfList.every(pdf => {
                            var fileInfo = responces.find(responce => responce.data.filename.toUpperCase().trim().endsWith(pdf.number + '.PDF')).data;
                            pdf.cdnLink = fileInfo.cdnLink;
                            pdf.size = fileInfo.size;
                            pdf.md5 = fileInfo.md5;

                            return pdf.cdnLink != null;

                        })) {
                            $log.error(responces);
                            return uploadFailed('cdnLink creation failed');
                        }

                        newDoc.docNumber = drawingInfo.partNo;
                        newDoc.drawingPack = drawingInfo;

                        addDocLinkToBlobStorage(newDoc, false);

                    }).catch(function (error) {
                        uploadFailed(error);
                    });
                }

                reader.readAsText(drawingsInfoFile);
            },


            downloadManifest: function () {
                var manifest = JSON.parse(angular.toJson(data.docs)).filter(o => o.docTypeId != 'drawings').sort((a, b) => {
                    var aCat = a.brand + '|' + a.family + '|' + a.machine + '|' + a.docTypeId + '|' + a.subTypeId + '|' + a.issue;
                    var bCat = b.brand + '|' + b.family + '|' + b.machine + '|' + b.docTypeId + '|' + b.subTypeId + '|' + b.issue;

                    return (aCat > bCat ? 1 : aCat < bCat ? -1 : 0);
                });

                manifest.forEach(m => m.sizeMB = Math.round(m.size / 1000) / 1000);

                const a4Paper = '9';
                var workbook = new ExcelJS.Workbook();
                var worksheet = workbook.addWorksheet('Documents', {
                    views: [{ state: 'frozen', ySplit: 1 }],
                    pageSetup: { paperSize: a4Paper, fitToWidth: 1, orientation: 'landscape' }
                });

                worksheet.columns = [
                    { key: "brand", header: "Brand", width: 15 },
                    { key: "family", header: "Family", width: 15 },
                    { key: "machine", header: "Machine Name ", width: 17 },
                    { key: "docTypeId", header: "Doc Type", width: 20 },
                    { key: "subTypeId", header: "Sub Type", width: 30 },
                    { key: "docNumber", header: "Doc Number", width: 20 },
                    { key: "issue", header: "Issue", width: 10 },
                    { key: "language", header: "Language", width: 11 },
                    { key: "description", header: "Description", width: 60 },
                    { key: "cdnLink", header: "CDN Link", width: 12 },
                    { key: "user", header: "Uploader Username", width: 35 },
                    { key: "timestamp", header: "Issued", width: 23 },
                    { key: "_id", header: "databse id", width: 25 },
                    { key: "createdAt", header: "createdAt", width: 25 },
                    { key: "updatedAt", header: "updatedAt", width: 25 },
                    { key: "sizeMB", header: "File Size (MB)", width: 15 }
                ];

                var stripe = false;

                manifest.forEach(doc => {

                    var category = $rootScope.getCategogy(doc.docTypeId);

                    if (category.subTypes) {
                        try {
                            doc.subTypeId = category.subTypes.find(o => o.id === doc.subTypeId).name;
                        }
                        catch (err) {
                            console.log("Unknown category id: %s", doc.subTypeId);
                        }
                    }

                    doc.docTypeId = category.name;
                    doc.language = $rootScope.info.languages.find(o => o.id == doc.language).name;

                    if ((doc.issue == null) || (typeof (doc.issue) == 'undefined')) {
                        doc.issue = '-';
                    }

                    if ((doc.description == null) || (typeof (doc.description) == 'undefined')) {
                        doc.description = '-';
                    }

                    doc.cdnLink = {
                        text: doc.cdnLink,
                        hyperlink: doc.cdnLink
                    };

                    var row = worksheet.addRow(doc);
                    row.eachCell((cell) => {
                        cell.font = { color: { argb: 'FF000000' }, bold: false },
                            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: stripe ? 'ffdce6f1' : 'FFFFFFFF' } }
                    });
                    stripe = !stripe;
                });

                worksheet.autoFilter = 'A1:' + String.fromCharCode("A".charCodeAt(0) + (worksheet.columns.length - 1)) + '1';
                worksheet.getRow(1).eachCell((cell) => {
                    cell.font = { color: { argb: 'FFFFFFFF' }, bold: true },
                        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF16365C' } }
                });

                const filename = "medisafe doc manifest " + moment(Date.now()).format('MMMM Do YYYY') + ".xlsx";
                workbook.creator = $rootScope.userInfo.userName;
                workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    }
                    else {
                        var e = document.createEvent('MouseEvents'),
                            a = document.createElement('a');

                        a.download = filename;
                        a.href = window.URL.createObjectURL(blob);
                        a.setAttribute("download", filename);
                        a.dataset.downloadurl = [blob.type, a.download, a.href].join(':');
                        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                        a.dispatchEvent(e);
                    }
                });
            },

            doesDocExistOnMachine: function (sourceDoc, targetMachine) {

                if ((sourceDoc == null) || (typeof (sourceDoc) == 'undefined'))
                    return false;

                var exists = data.docs.some(d => (d.machine === targetMachine) && (d.cdnLink === sourceDoc.cdnLink));
                return exists;
            },

            canReadBrand: function (brand) {
                if ((data == null) || (typeof (brand) == 'undefined'))
                    return false;

                return data.brands.some(o => o.toLowerCase() === brand.toLowerCase());
            },


            viewPdf: function (cdnLink, reference) {
                if (status.viewPdf) {
                    if (status.viewPdf.cdnLink === cdnLink) {
                        return;
                    }
                }

                status.viewPdf = {
                    cdnLink: cdnLink,
                    reference: reference
                };
            }
        }

    }]);


if (app) app.directive('drawingNode', ['infoLib', function (infoLib) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            drawing: '=',
            pdfList: '='
        },
        templateUrl: '../directives/templates/drawingNode.html',

        link: function (scope) {

            scope.pdfCdnLink = scope.pdfList.find(o => o.number == scope.drawing.partNo).cdnLink;

            scope.isSelected = function () {
                if (infoLib.status.viewPdf == null) {
                    return false;
                }
                return scope.drawing == infoLib.status.viewPdf.reference;
            }

            scope.selectDrawing = function () {
                if ((scope.pdfList == null) || (typeof (scope.pdfList) == 'undefined'))
                    return;

                if (scope.openLinkOnClick != true) {
                    infoLib.viewPdf(scope.pdfCdnLink, scope.drawing);
                }
            }
        }
    }
}])


if (app) app.directive('drawingNodeMobile', [function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            drawing: '=',
            pdfList: '='
        },
        templateUrl: '../directives/templates/drawingNodeMobile.html',

        link: function (scope) {
            scope.hrefLink = scope.pdfList.find(o => o.number == scope.drawing.partNo).cdnLink;
        }
    }
}])


if (app) app.directive('machineImageModel', ['infoLib', function (infoLib) {
    return {
        restrict: 'A',
        scope: {
            brand: '<',
            machine: '<'
        },
        link: function (scope, element) {
            element.bind('change', function () {
                scope.$apply(function () {
                    if (element[0].files != undefined) {

                        if (element[0].files.length > 0) {
                            infoLib.addImage(element[0].files, scope.brand, scope.machine);
                        }
                    }
                });
            });
        }
    };
}]);
