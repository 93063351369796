if (app) app.directive('uploadFile', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        scope: { inputId: '@' },
        link: function (scope, element) {

            element.bind('click', function () {
                $timeout(function () {
                    angular.element(scope.inputId).trigger('click');
                });
            });
        }
    };
}]);

if (app) app.directive('fileModel', ['$parse', function () {
    return {
        restrict: 'A',
        scope: { uploadMethod: '&' },
        link: function (scope, element) {
            scope.uploadMethod = scope.uploadMethod();

            element.bind('change', function () {
                scope.$apply(function () {
                    if (element[0].files != undefined) {

                        if (element[0].files.length > 0) {
                            scope.uploadMethod(element[0].files);
                        }
                    }
                });
            });
        }
    };
}]);


if (app) app.directive('onlyDigits', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attr, ctrl) {
            function inputValue(val) {
                if (val) {
                    var digits = val.replace(/[^0-9]/g, '');

                    if (digits !== val) {
                        ctrl.$setViewValue(digits);
                        ctrl.$render();
                    }
                    return parseInt(digits, 10);
                }
                return undefined;
            }
            ctrl.$parsers.push(inputValue);
        }
    };
});
