if (app) app.controller('UserAdminController', ['$scope', '$http', '$log', 'NgTableParams', 'editMode', '$location',
    function ($scope, $http, $log, NgTableParams, editMode, $location) {

        editMode.refresh();
        $scope.userTable = null;
        $scope.userData = null;
        $scope.userDataStatus = 'init';
        $scope.hideBlockedUsers = true;

        $scope.getRole = function (roleID) {
            var role = $scope.userData.authConfig.roles.find(o => o.id === roleID);
            return role;
        }

        $log.debug('fetching user list...');

        $http.get('admin/getUserList').then(function (response) {
            $scope.userData = response.data;
            $scope.userData.userList.forEach(user => {
                user.roleName = $scope.getRole(user.roleID).name;
            });

            $scope.userTable = new NgTableParams(
                {
                    count: 20,
                    sorting: { preferredUsername: "asc" }
                },
                {
                    counts: [20, 50, 100],
                    paginationMaxBlocks: 7,
                    paginationMinBlocks: 2,
                    filterDelay: 0,
                    dataset: $scope.userData.userList
                }
            );

            $scope.filterSelect = {                
                notify: [{ id: true, title: "Doc Changes" }],
                roles: $scope.userData.authConfig.roles.map(r => { return { id: r.id, title: r.name } })
                    .sort((a, b) => (a.title > b.title) ? 1 : -1)
            };

            $scope.refreshBlockedUsers();
            $scope.userDataStatus = 'ok';
            $log.debug('user list recieved:');
            $log.debug($scope.userData);

        }).catch(function (error) {
            if (error.status === 403) {
                $scope.userDataStatus = 'unauthorised';
                $log.debug("unauthorised user");
            } else {
                $scope.userDataStatus = 'error';
                $log.error("Unable to load user list");
                $log.debug(error);
            }
        });

        $scope.refreshBlockedUsers = function () {
            $scope.userTable.filter()['roleID'] = $scope.hideBlockedUsers ? '!blocked' : '';
        }

        $scope.updateUserRole = function (user, newRoleID) {
            return $http.post('/admin/updateUserRole', { oid: user.oid, newRoleID: newRoleID }).then(() => {
                user.roleName = $scope.getRole(user.roleID).name;
                $scope.userTable.reload();
            });
        }

        $scope.updateUserNotifications = function (user, notify) {
            return $http.post('/admin/updateUserNotifications', { oid: user.oid, notify: notify });
        }

        $scope.deleteUser = function (user) {
            if (confirm(`Are you sure you want to delete user: ${user.name}?`)) {
                return $http.post('/admin/deleteUser', { oid: user.oid })
                    .then(() => {
                        $scope.userData.userList.splice($scope.userData.userList.indexOf(user), 1);
                        $scope.userTable.reload();
                    });
            }
        }

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };
    }
]);
