if (app) app.controller('HomeController', ['$scope', '$rootScope', '$location', 'editMode',
    function ($scope, $rootScope, $location, editMode) {

        if (devEnabled && !$rootScope.isMobileBrowser) {            
            showAngularStats({
                "htmlId": "ngStatOverlay",
                "position": "bottomleft",
                "digestTimeThreshold": 16,
                "autoload": true,
                "logDigest": true,
                "logWatches": true
            }); 
        }

        editMode.refresh();

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };

    }]
)
