if (app) app.controller('DocHistoryController', ['$scope', 'editMode', '$location',
    function ($scope, editMode, $location) {

        editMode.refresh(); 

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };
    }
]);
