if (app) app.factory('editMode', ['$timeout', '$log', '$state', '$stateParams',
    function ($timeout, $log, $state, $stateParams) {

        var status = {
            isEnabled: false
        };

        return {
            status: status,

            refresh: function () {
                var enable = $stateParams.editingEnabled || status.isEnabled;

                if ((enable == null) || (typeof (enable) == 'undefined')) {
                    this.set(false);
                } else {
                    this.set(enable);
                }
            },

            set: function (enable) {

                status.isEnabled = enable;

                $timeout(function () {
                    $log.debug(status.isEnabled ? 'edit mode set' : 'edit mode unset');
                    $state.go('.', { editingEnabled: status.isEnabled });
                });
            },

            toggle: function () {
                this.set(!status.isEnabled);
            }
        };

    }]
);