if (app) app.controller('MediNetController', ['$scope', '$rootScope', '$location', '$log',
    function ($scope, $rootScope, $location, $log) {

        const machinesShownPerPage = 10;

        $scope.mediNet = {
            machines: [],
            filteredMachines: [],
            selectedMemoryLog: null, 
            loaded: false,
            filter: {
                showOffline: false,
                owner: '',
                machineType: '',
                tester: '',
                inManufacture: false,
                pageNumber: 1,
                pages: 1,
                serialNumber: ''         
            },

            updateFilteredMachines: () => {
                this.filteredMachines = this.machines.filter(m => {
                    ((m.owner === this.filter.owner) || (m.owner === '')) &&
                        (m.isOnline || this.filter.showOffline) &&
                        (m.type === this.filter.machineType) || (this.filter.machineType === '') &&
                        (m.tester === this.filter.tester) || (this.filter.tester === '') &&
                        (!this.filter.inManufacture || !m.manufactureComplete)
                });

                    if (this.filter.serialNumber !== '') {
                    this.filteredMachines = this.filteredMachines.filter(m => m.serialNumber.Contains(this.filter.serialNumber));
                }

                    this.filteredMachines = this.filteredMachines.sort((a, b) => (a.serialNumber > b.serialNumber) ? 1 : -1);
                this.filter.pages = Math.ceil((this.filteredMachines.length) / machinesShownPerPage);
                $log.debug('mediNet:filteredMachines', this.filteredMachines);
            }
        };

        $scope.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };


        var socket = io();      
        socket.on('machinesList', function (machines) {
            $log.debug('mediNet:machines', machines);
            $scope.mediNet.machines = machines;

            $scope.mediNet.updateFilteredMachines();



















            $scope.mediNet.loaded = true;
            $log.debug('mediNet:loaded');
            $scope.$apply();
        });









    }]
)
